<script lang="ts" setup></script>

<template>
  <svg width="121" height="44" viewBox="0 0 121 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_575_5938)">
      <path
        d="M49.9375 40.1641V15.4934C49.9375 13.3139 50.8033 11.2236 52.3445 9.6824C53.8857 8.14122 55.976 7.27539 58.1555 7.27539L65.4389 7.28555C67.6128 7.28992 69.6961 8.15665 71.2316 9.69549C72.7671 11.2343 73.6293 13.3195 73.629 15.4934V20.7435C73.629 22.9232 72.7632 25.0136 71.2221 26.555C69.6809 28.0964 67.5906 28.9625 65.411 28.9629H55.1165"
        stroke="#939598"
        stroke-width="0.795008"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.665 7.27441H78.8248C79.7193 7.27441 80.5773 7.62979 81.2099 8.26236C81.8424 8.89494 82.1978 9.75289 82.1978 10.6475V29.0749"
        stroke="#939598"
        stroke-width="0.795008"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.5195 4.41965L80.0869 2.98585C80.0024 2.9014 79.9353 2.80112 79.8896 2.69075C79.8439 2.58038 79.8203 2.46208 79.8203 2.3426C79.8203 2.22312 79.8439 2.10482 79.8896 1.99445C79.9353 1.88408 80.0024 1.7838 80.0869 1.69936L81.5195 0.266816C81.6904 0.0959694 81.9223 0 82.164 0C82.4057 0 82.6375 0.0959694 82.8085 0.266816L84.2398 1.69936C84.3243 1.7838 84.3913 1.88408 84.4371 1.99445C84.4828 2.10482 84.5064 2.22312 84.5064 2.3426C84.5064 2.46208 84.4828 2.58038 84.4371 2.69075C84.3913 2.80112 84.3243 2.9014 84.2398 2.98585L82.8021 4.41965C82.7176 4.50421 82.6172 4.57129 82.5067 4.61705C82.3962 4.66281 82.2778 4.68636 82.1583 4.68636C82.0387 4.68636 81.9203 4.66281 81.8098 4.61705C81.6993 4.57129 81.5989 4.50421 81.5144 4.41965"
        fill="#32BCAD"
      />
      <path
        d="M88.2637 7.25781H91.3967C93.0083 7.25714 94.5542 7.8967 95.6943 9.03579L103.023 16.3648C103.249 16.5908 103.517 16.77 103.812 16.8922C104.107 17.0145 104.423 17.0774 104.742 17.0774C105.062 17.0774 105.378 17.0145 105.673 16.8922C105.968 16.77 106.236 16.5908 106.461 16.3648L113.764 9.06373C114.328 8.49963 114.998 8.05226 115.735 7.74719C116.472 7.44211 117.262 7.28531 118.06 7.28575H120.608"
        stroke="#939598"
        stroke-width="0.795008"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M88.2637 28.9133H91.3967C93.0083 28.9138 94.5542 28.2743 95.6943 27.1353L103.023 19.8063C103.479 19.3507 104.098 19.0947 104.742 19.0947C105.387 19.0947 106.005 19.3507 106.461 19.8063L113.764 27.1087C114.903 28.2476 116.449 28.8871 118.06 28.8866H120.608"
        stroke="#939598"
        stroke-width="0.795008"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="logo-bottom"
        d="M31.2581 33.5943C30.4851 33.5971 29.7192 33.4467 29.0046 33.1518C28.2901 32.8569 27.6411 32.4233 27.0951 31.8761L21.0817 25.8589C20.869 25.6555 20.5861 25.542 20.2918 25.542C19.9975 25.542 19.7146 25.6555 19.5019 25.8589L13.4682 31.8926C12.9225 32.4406 12.2737 32.8751 11.5592 33.1709C10.8446 33.4667 10.0785 33.6179 9.3052 33.6159H8.12793L15.7478 41.2358C16.8906 42.3762 18.4392 43.0167 20.0537 43.0167C21.6682 43.0167 23.2167 42.3762 24.3596 41.2358L31.9947 33.5994L31.2581 33.5943Z"
        fill="#32BCAD"
      />
      <path
        id="logo-top"
        d="M9.30647 12.3302C10.0798 12.3281 10.8459 12.4794 11.5605 12.7752C12.275 13.071 12.9239 13.5055 13.4695 14.0535L19.5032 20.0885C19.7129 20.2976 19.9969 20.415 20.2931 20.415C20.5892 20.415 20.8733 20.2976 21.083 20.0885L27.0951 14.0764C27.6403 13.5276 28.289 13.0926 29.0036 12.7965C29.7183 12.5005 30.4846 12.3493 31.2581 12.3518H31.982L24.3469 4.71664C23.7815 4.15105 23.1102 3.70238 22.3714 3.39628C21.6326 3.09017 20.8407 2.93262 20.041 2.93262C19.2413 2.93262 18.4494 3.09017 17.7106 3.39628C16.9718 3.70238 16.3005 4.15105 15.7351 4.71664L8.12793 12.3302H9.30647Z"
        fill="#32BCAD"
      />
      <path
        id="logo-left-right"
        d="M38.2975 18.6683L33.6837 14.0545C33.5796 14.097 33.4684 14.1194 33.356 14.1205H31.258C30.1663 14.1233 29.1197 14.5569 28.3459 15.327L22.3339 21.3353C21.7924 21.8758 21.0586 22.1793 20.2936 22.1793C19.5286 22.1793 18.7948 21.8758 18.2534 21.3353L12.2185 15.3042C11.4449 14.5335 10.3983 14.0995 9.30639 14.0964H6.73086C6.62472 14.094 6.51983 14.073 6.42099 14.0342L1.77794 18.6683C0.637537 19.8112 -0.00292969 21.3597 -0.00292969 22.9742C-0.00292969 24.5887 0.637537 26.1373 1.77794 27.2801L6.41083 31.913C6.50945 31.8735 6.61448 31.8524 6.7207 31.8507H9.30639C10.3983 31.8475 11.4448 31.4135 12.2185 30.643L18.2521 24.6093C19.343 23.5197 21.2442 23.5197 22.3339 24.6093L28.3459 30.6201C29.1197 31.3903 30.1663 31.8239 31.258 31.8266H33.356C33.4685 31.8274 33.5797 31.8498 33.6837 31.8927L38.2975 27.2788C38.8631 26.7134 39.3118 26.0422 39.6179 25.3034C39.924 24.5645 40.0815 23.7727 40.0815 22.9729C40.0815 22.1732 39.924 21.3813 39.6179 20.6425C39.3118 19.9037 38.8631 19.2325 38.2975 18.6671"
        fill="#32BCAD"
      />
      <path
        d="M56.2014 38.067C55.82 38.0837 55.4415 38.1412 55.0724 38.2384V39.7421C55.3547 39.8411 55.652 39.8909 55.9512 39.8894C56.7056 39.8894 57.0624 39.6354 57.0624 38.9712C57.0624 38.3464 56.7703 38.067 56.2014 38.067ZM54.8184 40.861V37.8854H55.0254L55.047 38.0124C55.4316 37.9102 55.8256 37.8468 56.223 37.8232C56.4983 37.8039 56.7715 37.8831 56.9938 38.0467C57.2478 38.2575 57.3342 38.5979 57.3342 38.9687C57.3342 39.3395 57.2072 39.7231 56.8516 39.925C56.5802 40.0604 56.2798 40.1271 55.9766 40.1193C55.67 40.1174 55.3654 40.0707 55.0724 39.9809V40.8559L54.8184 40.861Z"
        fill="#939598"
      />
      <path
        d="M59.2267 38.0586C58.4736 38.0586 58.137 38.2948 58.137 38.959C58.137 39.6003 58.4685 39.8899 59.2267 39.8899C59.9848 39.8899 60.3125 39.6562 60.3125 38.9933C60.3125 38.3519 59.981 38.0586 59.2267 38.0586ZM60.1969 39.8899C59.9429 40.0702 59.6102 40.1223 59.2267 40.1223C58.8431 40.1223 58.499 40.0664 58.2538 39.8899C57.9783 39.6956 57.8652 39.3819 57.8652 38.9768C57.8652 38.5717 57.9783 38.2567 58.2538 38.0586C58.499 37.882 58.8355 37.8262 59.2267 37.8262C59.6178 37.8262 59.9467 37.882 60.1969 38.0586C60.4763 38.2567 60.5843 38.5793 60.5843 38.9717C60.5843 39.3641 60.4725 39.6956 60.1969 39.8899Z"
        fill="#939598"
      />
      <path
        d="M63.7656 40.0612L62.9249 38.2566H62.9084L62.0804 40.0612H61.848L60.959 37.8857H61.2384L61.9839 39.712H62.0016L62.8119 37.8857H63.0443L63.8749 39.712H63.8926L64.6203 37.8857H64.8959L63.9993 40.0612H63.7656Z"
        fill="#939598"
      />
      <path
        d="M66.5152 38.0533C65.8167 38.0533 65.5805 38.3645 65.5373 38.8153H67.4931C67.4715 38.32 67.2175 38.0533 66.5152 38.0533ZM66.5063 40.1221C66.0885 40.1221 65.8167 40.0612 65.6008 39.8808C65.3468 39.6611 65.2617 39.3424 65.2617 38.9766C65.2617 38.6109 65.3773 38.2565 65.6668 38.0457C65.9201 37.8864 66.2165 37.8097 66.5152 37.826C66.8197 37.8076 67.1228 37.8811 67.3851 38.0368C67.6988 38.2476 67.7598 38.6185 67.7598 39.0401H65.531C65.5398 39.4884 65.6859 39.8847 66.5304 39.8847C66.908 39.8747 67.2841 39.8322 67.6544 39.7577V39.9862C67.2779 40.0637 66.8955 40.1083 66.5114 40.1196"
        fill="#939598"
      />
      <path
        d="M68.4902 40.061V37.8855H68.696L68.7176 38.0125C69.1786 37.8956 69.3945 37.8232 69.7996 37.8232H69.8301V38.0645H69.7691C69.4288 38.0645 69.2217 38.1115 68.7442 38.236V40.0584L68.4902 40.061Z"
        fill="#939598"
      />
      <path
        d="M71.3062 38.0533C70.609 38.0533 70.3715 38.3645 70.3283 38.8153H72.2841C72.2625 38.32 72.0085 38.0533 71.3062 38.0533ZM71.2973 40.1221C70.8795 40.1221 70.609 40.0612 70.3931 39.8808C70.1391 39.6611 70.0527 39.3424 70.0527 38.9766C70.0527 38.6109 70.1696 38.2565 70.4579 38.0457C70.7112 37.8868 71.0075 37.81 71.3062 37.826C71.6107 37.8076 71.9138 37.8811 72.1761 38.0368C72.4911 38.2476 72.5571 38.6185 72.5571 39.0401H70.3194C70.3283 39.4884 70.4744 39.8847 71.3189 39.8847C71.6969 39.8748 72.0734 39.8323 72.4441 39.7577V39.9862C72.0677 40.0636 71.6853 40.1082 71.3011 40.1196"
        fill="#939598"
      />
      <path
        d="M75.3351 38.2044C75.0526 38.1059 74.7554 38.0565 74.4563 38.0584C73.7019 38.0584 73.345 38.3124 73.345 38.9766C73.345 39.6052 73.6384 39.8808 74.2061 39.8808C74.5872 39.8632 74.9655 39.8066 75.3351 39.7119V38.2044ZM75.3833 40.0612L75.3605 39.9342C74.976 40.0375 74.5819 40.1013 74.1845 40.1247C73.9088 40.1454 73.6349 40.0656 73.4136 39.8999C73.1596 39.689 73.0732 39.3487 73.0732 38.9791C73.0732 38.5905 73.2002 38.2248 73.5558 38.0266C73.8276 37.8881 74.1298 37.82 74.4347 37.8285C74.7397 37.8327 75.0427 37.8789 75.3351 37.9657V36.9688H75.5891V40.0612H75.3833Z"
        fill="#939598"
      />
      <path
        d="M79.3166 38.0673C78.9352 38.084 78.5567 38.1415 78.1876 38.2387V39.7386C78.4693 39.8404 78.7669 39.8916 79.0664 39.8897C79.8208 39.8897 80.1777 39.6357 80.1777 38.9715C80.1777 38.3467 79.8856 38.0673 79.3166 38.0673ZM79.9668 39.9278C79.6959 40.0631 79.3958 40.1298 79.0931 40.1221C78.7617 40.12 78.4328 40.0647 78.119 39.9583L78.1063 40.0612H77.9336V36.9688H78.1876V38.0063C78.5645 37.9099 78.9498 37.8495 79.3382 37.826C79.6135 37.8067 79.8868 37.8859 80.1091 38.0495C80.3631 38.2603 80.4494 38.6007 80.4494 38.9715C80.4494 39.3423 80.3224 39.7259 79.9668 39.9278Z"
        fill="#939598"
      />
      <path
        d="M80.7876 40.893V40.6567C80.9146 40.6694 81.0289 40.6783 81.1102 40.6783C81.4251 40.6783 81.6182 40.5869 81.7909 40.23L81.8735 40.0573L80.7305 37.8818H81.0251L81.9992 39.7601H82.0157L82.9415 37.8818H83.2311L82.0068 40.3253C81.7833 40.7685 81.542 40.9146 81.0975 40.9146C80.9926 40.914 80.8878 40.9068 80.7838 40.893"
        fill="#939598"
      />
      <path
        d="M86.7396 38.8207H85.8963V39.5827H86.7396C87.3212 39.5827 87.541 39.5179 87.541 39.2017C87.541 38.8614 87.2387 38.8207 86.7345 38.8207H86.7396ZM86.5821 37.5914H85.8938V38.3661H86.5872C87.16 38.3661 87.3886 38.2975 87.3886 37.9749C87.3886 37.6295 87.099 37.5939 86.5821 37.5939V37.5914ZM87.8927 39.8646C87.5816 40.0628 87.2069 40.0704 86.5224 40.0704H85.2334V37.1113H86.4919C87.0825 37.1113 87.4444 37.1189 87.7454 37.3006C87.8454 37.3602 87.9268 37.4466 87.9802 37.5501C88.0336 37.6536 88.057 37.7699 88.0477 37.886C88.0477 38.1959 87.9207 38.4029 87.5816 38.5413V38.5578C87.9626 38.6442 88.2064 38.8385 88.2064 39.2563C88.216 39.3756 88.1916 39.4952 88.1361 39.6013C88.0806 39.7074 87.9962 39.7956 87.8927 39.8558"
        fill="#939598"
      />
      <path
        d="M90.7825 39.1486C90.5285 39.127 90.2745 39.1143 89.9989 39.1143C89.5557 39.1143 89.3994 39.2045 89.3994 39.4077C89.3994 39.6109 89.5264 39.6998 89.8693 39.6998C90.1773 39.6897 90.4834 39.6471 90.7825 39.5728V39.1486ZM90.9095 40.0617L90.8929 39.9347C90.5079 40.0433 90.1116 40.1072 89.7119 40.1252C89.4809 40.1408 89.2508 40.0849 89.0527 39.9652C88.9676 39.8937 88.9015 39.8023 88.8604 39.6991C88.8192 39.5958 88.8042 39.484 88.8168 39.3736C88.8294 39.2632 88.8691 39.1576 88.9325 39.0662C88.9958 38.9749 89.0807 38.9007 89.1797 38.8502C89.4083 38.7422 89.7144 38.7333 89.9951 38.7333C90.2224 38.7333 90.5285 38.746 90.7825 38.7638V38.7244C90.7825 38.3841 90.5589 38.2723 89.9468 38.2723C89.7106 38.2723 89.421 38.285 89.1455 38.3104V37.871C89.4515 37.8456 89.797 37.8291 90.0814 37.8291C90.4624 37.8291 90.8523 37.8583 91.0974 38.031C91.3425 38.2037 91.3946 38.4539 91.3946 38.7765V40.0643L90.9095 40.0617Z"
        fill="#939598"
      />
      <path
        d="M94.2328 40.0608V38.8607C94.2328 38.4645 94.0308 38.3222 93.6689 38.3222C93.3685 38.3356 93.0704 38.3816 92.7799 38.4594V40.0621H92.1729V37.8854H92.6681L92.6897 38.0238C93.0684 37.9123 93.4588 37.8458 93.853 37.8257C94.1207 37.8043 94.3867 37.884 94.5985 38.0492C94.77 38.2041 94.8347 38.42 94.8347 38.7299V40.0608H94.2328Z"
        fill="#939598"
      />
      <path
        d="M96.5519 40.1223C96.2712 40.1223 95.9651 40.0829 95.7416 39.8937C95.4749 39.6778 95.3975 39.3387 95.3975 38.9717C95.3975 38.6275 95.5092 38.2516 95.8407 38.0408C96.1125 37.8643 96.4477 37.8262 96.797 37.8262C97.051 37.8262 97.2923 37.8427 97.559 37.8681V38.3342C97.3393 38.3126 97.0764 38.2961 96.8656 38.2961C96.2877 38.2961 96.0159 38.4764 96.0159 38.9768C96.0159 39.4454 96.2191 39.6486 96.6928 39.6486C96.9999 39.6386 97.3055 39.6012 97.606 39.5368V39.9839C97.2583 40.0648 96.9035 40.1112 96.5468 40.1223"
        fill="#939598"
      />
      <path
        d="M99.4049 38.2821C98.8271 38.2821 98.5731 38.4637 98.5731 38.959C98.5731 39.4543 98.8271 39.6651 99.4049 39.6651C99.9827 39.6651 100.228 39.4886 100.228 38.9933C100.228 38.498 99.9827 38.2821 99.4049 38.2821ZM100.448 39.8937C100.181 40.074 99.8316 40.1223 99.4049 40.1223C98.9782 40.1223 98.6213 40.0702 98.3584 39.8937C98.0562 39.6956 97.9482 39.3679 97.9482 38.9768C97.9482 38.5856 98.0562 38.2516 98.3584 38.0535C98.6213 37.877 98.9693 37.8262 99.4049 37.8262C99.8405 37.8262 100.181 37.877 100.448 38.0535C100.749 38.2516 100.853 38.5843 100.853 38.9717C100.853 39.359 100.745 39.6956 100.448 39.8937Z"
        fill="#939598"
      />
      <path
        d="M104.36 40.1219C103.994 40.1219 103.598 40.0609 103.301 39.8158C102.948 39.5225 102.84 39.0703 102.84 38.5839C102.84 38.1483 102.978 37.6315 103.438 37.3305C103.796 37.0981 104.239 37.0498 104.688 37.0498C105.015 37.0498 105.352 37.0714 105.718 37.1019V37.6315C105.404 37.6061 105.015 37.5845 104.714 37.5845C103.874 37.5845 103.517 37.9032 103.517 38.5839C103.517 39.2647 103.848 39.5872 104.468 39.5872C104.911 39.5719 105.351 39.5128 105.782 39.4107V39.9365C105.315 40.0444 104.839 40.1065 104.36 40.1219Z"
        fill="#939598"
      />
      <path
        d="M107.568 38.2134C107.06 38.2134 106.869 38.395 106.83 38.7214H108.295C108.278 38.3684 108.071 38.2134 107.568 38.2134ZM107.476 40.1184C107.119 40.1184 106.796 40.0752 106.554 39.8771C106.313 39.679 106.205 39.3386 106.205 38.9678C106.205 38.6363 106.313 38.2655 106.615 38.0496C106.882 37.8604 107.222 37.8223 107.568 37.8223C107.878 37.8223 108.243 37.8566 108.51 38.042C108.859 38.2871 108.891 38.6668 108.891 39.1138H106.826C106.839 39.4466 107.015 39.6612 107.627 39.6612C108.016 39.6527 108.404 39.6154 108.787 39.5494V39.9762C108.355 40.0583 107.916 40.1058 107.476 40.1184Z"
        fill="#939598"
      />
      <path
        d="M111.595 40.0608V38.8607C111.595 38.4645 111.393 38.3222 111.031 38.3222C110.731 38.3356 110.433 38.3816 110.142 38.4594V40.0621H109.535V37.8854H110.03L110.052 38.0238C110.431 37.9123 110.821 37.8458 111.215 37.8257C111.483 37.8043 111.749 37.884 111.961 38.0492C112.132 38.2041 112.197 38.42 112.197 38.7299V40.0608H111.595Z"
        fill="#939598"
      />
      <path
        d="M113.929 40.1221C113.635 40.1221 113.369 40.0396 113.222 39.811C113.103 39.6134 113.047 39.3836 113.062 39.1531V38.3378H112.623V37.8857H113.062L113.127 37.2266H113.663V37.8857H114.52V38.3378H113.663V39.0363C113.655 39.1769 113.675 39.3177 113.723 39.4503C113.788 39.5963 113.93 39.6522 114.12 39.6522C114.266 39.6496 114.411 39.6351 114.555 39.609V40.0447C114.348 40.0894 114.138 40.1153 113.926 40.1221"
        fill="#939598"
      />
      <path
        d="M115.116 40.0605V37.885H115.612L115.633 38.0235C115.981 37.9055 116.343 37.8388 116.71 37.8253C116.736 37.8238 116.762 37.8238 116.788 37.8253V38.3524C116.719 38.3524 116.636 38.3524 116.577 38.3524C116.289 38.3536 116.002 38.3911 115.723 38.4641V40.0707L115.116 40.0605Z"
        fill="#939598"
      />
      <path
        d="M118.997 39.1486C118.743 39.127 118.489 39.1143 118.212 39.1143C117.769 39.1143 117.614 39.2045 117.614 39.4077C117.614 39.6109 117.741 39.6998 118.082 39.6998C118.391 39.6897 118.697 39.6471 118.997 39.5728V39.1486ZM119.124 40.0617L119.106 39.9347C118.721 40.0433 118.325 40.1072 117.925 40.1252C117.694 40.1408 117.464 40.0849 117.266 39.9652C117.186 39.9039 117.122 39.8239 117.08 39.7323C117.038 39.6406 117.019 39.5401 117.024 39.4394C117.019 39.3156 117.051 39.1929 117.116 39.0876C117.182 38.9823 117.278 38.8995 117.391 38.8502C117.62 38.7422 117.925 38.7333 118.206 38.7333C118.434 38.7333 118.74 38.746 118.994 38.7638V38.7244C118.994 38.3841 118.769 38.2723 118.159 38.2723C117.921 38.2723 117.633 38.285 117.357 38.3104V37.871C117.663 37.8456 118.007 37.8291 118.292 37.8291C118.673 37.8291 119.063 37.8583 119.308 38.031C119.553 38.2037 119.605 38.4539 119.605 38.7765V40.0643L119.124 40.0617Z"
        fill="#939598"
      />
      <path d="M120.385 36.9688H120.993V40.0612H120.385V36.9688Z" fill="#939598" />
    </g>
    <defs>
      <clipPath id="clip0_575_5938">
        <rect width="121" height="43.0143" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
